import React, {useEffect, useState} from 'react';
import {Link, Route, Switch} from 'react-router-dom';
import './App.scss';
import Import from "./Components/Import";
import Footer from "./Components/Footer";
import Dashboard2 from "./Components/Dashboard2";
import {Container, Image, Menu} from 'semantic-ui-react'
import logo from "./Images/cut-transparent.png"
import {useDevelopment} from "./context/DevelopmentContext";


function App() {

    const development = useDevelopment();
    const [url, setUrl] = useState(development.url);

    function DashboardChild({match}) {
        return (
            <div>
                <Dashboard2 pageNumber={match.params.pageNumber} url={url}/>
            </div>
        );
    }

    useEffect(() => {
        setUrl(development.url);
    }, [development.url])

    return (
        <div className="App">
            <Menu fixed='top' inverted>
                <Container>
                    <Menu.Item as='a' header>
                        <Image size='tiny' src={logo} style={{marginRight: '.5em'}}/>
                    </Menu.Item>
                    <Menu.Item as={Link} to='/'>Home</Menu.Item>
                    <Menu.Item as={Link} to='/dashboard/1'>Dashboard</Menu.Item>
                    <Menu.Item>
                            <span className="header-dropdown">
                                    <p>Development Mode</p>
                                    <select onChange={(e) => development.toggleMode(e)}>
                                        <option selected={true} key="acceptatie"
                                                value="acceptatie">{"acceptatie"}</option>
                                        <option key={"productie"} value={"productie"}>{"productie"}</option>
                                    </select>
                            </span>
                    </Menu.Item>
                </Container>
            </Menu>


            <div className="page-content">
                <Switch>
                    <Route exact path="/" component={Import}/>
                    <Route exact path="/import" component={Import}/>
                    <Route path="/dashboard/:pageNumber" component={DashboardChild}/>
                </Switch>
            </div>

            <Footer/>
        </div>

    );
}

export default App;
