import React, {createContext, useContext, useState} from 'react'

const acceptatieUrl = "https://bvgo-we-wa-p-be-pluswonen-001.azurewebsites.net/hive/api/v3/configuration";
const productieUrl = "https://bvgo-we-wa-p-be-pluswonen-001.azurewebsites.net/hive/api/v3/configuration";

const DevelopmentContext = createContext({
    mode: "acceptatie",
    url: acceptatieUrl,
    toggleMode: (e) => {
    },
});

const useDevelopment = () => useContext(DevelopmentContext);

const DevelopmentProvider = ({children}) => {

    const [mode, setMode] = useState("acceptatie");
    const [url, setUrl] = useState(acceptatieUrl);

    const toggleMode = (e) => {
        if (e.target.value === "productie") {
            setMode("productie");
            setUrl(productieUrl)
        } else if (e.target.value === "acceptatie") {
            setMode("acceptatie");
            setUrl(acceptatieUrl)
        }
    };

    return (
        <DevelopmentContext.Provider value={{
            mode,
            url,
            toggleMode,
        }}>
            {children}
        </DevelopmentContext.Provider>
    )
};

export {DevelopmentProvider, useDevelopment}
